/* eslint-disable react/display-name */
import { useInView } from 'react-intersection-observer'
import styles from './DesktopNavPlaceholder.module.css'
import { DynamicLoadingComponent } from 'shared-definitions/types'
import dynamic from 'next/dynamic'
import React from 'react'

const TopNavLoading = React.forwardRef<HTMLDivElement, DynamicLoadingComponent>((_props, ref) => (
  <div ref={ref} className={styles.desktopNav} />
))

const DesktopNav = dynamic(() => import('shared-components/layout/DesktopNav'), {
  loading: TopNavLoading,
  ssr: false,
})

const TopNavPlaceholder: React.VFC = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  if (inView) {
    return <DesktopNav className={styles.desktopNav} />
  }

  return <TopNavLoading ref={ref} />
}

export default TopNavPlaceholder
