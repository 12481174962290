/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link'
import { CSSProperties } from 'react'
import { BasicStyledComponent, DSDataAttrs, LinkedLinkProps } from 'shared-definitions/types'

export interface LinkedProps extends LinkedLinkProps, DSDataAttrs {
  title?: string
  onClick?: () => void
  style?: CSSProperties
}

const Linked: React.FC<LinkedProps & BasicStyledComponent> = ({
  children,
  href,
  className,
  target,
  rel,
  referrerPolicy,
  title,
  onClick,
  style,
  ...dataAttrs
}) => {
  if (target === '_blank') {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        {...dataAttrs}
        referrerPolicy={referrerPolicy}
        style={style}
        href={href}
        className={className}
        onClick={onClick}
        title={title}
        target="_blank"
        rel={rel}
      >
        {children}
      </a>
    )
  }
  return (
    <Link href={href} prefetch={false}>
      <a
        className={className}
        referrerPolicy={referrerPolicy}
        style={style}
        title={title}
        onClick={onClick}
        {...dataAttrs}
        rel={rel}
      >
        {children}
      </a>
    </Link>
  )
}

export default Linked
