import clsx from 'clsx'
import { useEffect, useRef } from 'react'
import { htmlReactParse } from 'shared-code/html'
import { observeScroll } from 'shared-code/observer'
import { DealBarContext } from 'shared-components/contexts/DealBarContext'
import Linked from 'shared-components/service/Linked'
import AppImage from 'shared-components/ui/AppImage'
import { BasicStyledComponent } from 'shared-definitions/types'
import ArrowRightBird from 'shared-svg/ArrowRightBird.svg'
import Cart from 'shared-svg/Cart.svg'
import styles from './DealBar.module.css'

type DealBarProps = BasicStyledComponent

const DealBar: React.VFC<DealBarProps> = ({ className }) => {
  const { setState, hiddenOnMobile, dealbar: dealbarData } = DealBarContext.useContainer()
  const container = useRef<HTMLDivElement>(null)
  const timeout = useRef<NodeJS.Timeout>()
  const sticky = dealbarData?.sticky ?? false

  useEffect(() => {
    const { current } = container
    if (!current) {
      return
    }
    const observerX2 = observeScroll(current, window.innerHeight * 2, hidden => {
      if (timeout.current !== undefined) {
        clearTimeout(timeout.current)
      }
      timeout.current = setTimeout(() => {
        setState(s => ({ ...s, hiddenOnMobile: hidden }))
        timeout.current = undefined
      }, 300)
    })
    return () => {
      observerX2.disconnect()
    }
  }, [dealbarData, setState])

  return (
    <div
      ref={container}
      className={clsx(className, styles.container, {
        [styles.containerVisible]: dealbarData !== null,
        [styles.containerScrolled]: hiddenOnMobile,
        [styles.sticky]: sticky,
      })}
    >
      {dealbarData ? (
        <Linked
          className={styles.link}
          {...dealbarData.pLink}
          style={dealbarData.color ? { backgroundColor: dealbarData.color } : undefined}
        >
          <div className={styles.linkContent}>
            {dealbarData.icon ? (
              <div className={styles.iconWrapper}>
                <AppImage
                  sizes={120}
                  layout="responsive"
                  className={styles.iconContainer}
                  imageClassName={styles.icon}
                  image={dealbarData.icon}
                />
              </div>
            ) : (
              <Cart className={styles.iconCart} />
            )}
            <span className={styles.linkLabel}>{htmlReactParse(dealbarData.label)}</span>
            <ArrowRightBird className={styles.iconArrow} />
          </div>
        </Linked>
      ) : null}
    </div>
  )
}

export default DealBar
