import { useRef, useEffect } from 'react'

export function useWindowEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: (ev: WindowEventMap[K]) => any
): void {
  // Create a ref that stores handler
  const savedHandler = useRef(handler)

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      // Create event listener that calls handler function stored in ref
      const eventListener = (event: WindowEventMap[K]): void => {
        savedHandler.current(event)
      }

      // Add event listener
      window.addEventListener(eventName, eventListener, { passive: true })

      // Remove event listener on cleanup
      return () => {
        window.removeEventListener(eventName, eventListener)
      }
    },
    [eventName] // Re-run if eventName or element changes
  )
}
