import Head from 'next/head'

interface GtmGtagProps {
  id: string
}

const GtmGtag: React.VFC<GtmGtagProps> = ({ id }) => (
  <Head>
    <script key="raw-1" async src={`https://www.googletagmanager.com/gtag/js?id=${id}`} />
    <script
      key="raw-2"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
    window._pwGA4PageviewId = ''.concat(Date.now());
    window.dataLayer = window.dataLayer || [];
    window.gtag = window.gtag || function () {
        dataLayer.push(arguments);
    };
    gtag('js', new Date());
    gtag('config', '${id}', { 'send_page_view': false });
    gtag(
        'event',
        'ramp_js',
        {
            'send_to': '${id}',
            'pageview_id': window._pwGA4PageviewId
        }
    );
`,
      }}
    />
  </Head>
)

export default GtmGtag
