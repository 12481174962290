import { useEffect } from 'react'
import { logNotice } from 'shared-code/log'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'
import { PostContext } from 'shared-components/contexts/PostContext'

export function useAdPath(): void {
  const { inst } = PlaywireContext.useContainer()
  const { tags } = PostContext.useContainer()

  useEffect(() => {
    if (!inst) {
      return
    }
    const { setPath } = inst

    const path = inferePath(tags.map(i => i.label.toLowerCase()))
    logNotice(`Setting tyche path to ${path}`)
    try {
      void setPath(path)
    } catch (e) {
      logNotice('Setting path error: ', e)
    }
  }, [tags, inst])
}

function inferePath(tagLabels: string[]): string {
  const paths: { path: string; labels: string[] }[] = [
    {
      path: 'NSFW',
      labels: ['nsfw'],
    },
    {
      path: 'campaigns',
      labels: ['samsung', 'google pixel', 'verizon', 't-mobile', 'att'],
    },
  ]

  for (const path of paths) {
    for (const label of tagLabels) {
      if (path.labels.includes(label)) {
        return path.path
      }
    }
  }
  return 'ROS'
}
