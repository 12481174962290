import { DSDealPrice } from 'shared-definitions/types'

export function wrapWithCurrency(priceText: string, currency: string): string {
  return `${currency}${priceText}`
}

type PriceForFormat = Pick<DSDealPrice, 'current' | 'isFree' | 'currency'>
export function priceCurrencyText(input: PriceForFormat, fraction = 2): string {
  return input.isFree || !input.current
    ? 'FREE'
    : wrapWithCurrency(priceFormat(input.current, fraction), input.currency)
}

export function priceFormat(priceValue: number, fraction = 2): string {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  })
  return formatter.format(priceValue)
}

export function timeFormat(seconds: number): string {
  if (seconds >= 3600) {
    return '59:59'
  }
  const date = new Date(0)
  date.setSeconds(seconds)
  return date.toISOString().substring(14, 19)
}

export function padZeros(num: number, size: number): string {
  const numStr = `${num}`
  if (numStr.length > size) {
    return numStr
  }
  return `000000000${numStr}`.slice(-size)
}
