/* eslint-disable react/jsx-no-literals */

import clsx from 'clsx'
import Header from 'components/Header'
import Thirdparty from 'components/service/Thirdparty'
import { publicConfig } from 'config'
import { getds } from 'ds'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import { DealBarContext } from 'shared-components/contexts/DealBarContext'
import { FreestarContext } from 'shared-components/contexts/FreestarContext'
import { NotificationsContext } from 'shared-components/contexts/NotificationsContext'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'
import { ThemeContext } from 'shared-components/contexts/ThemeContext'
import { UIVariantContext } from 'shared-components/contexts/UIVariantContext'
import { useOutboundLinkListener } from 'shared-components/hooks/use-outbound-link-listener'
import Footer from 'shared-components/layout/Footer'
import PageTransition from 'shared-components/layout/PageTransition'
import PopupWrapper from 'shared-components/layout/PopupWrapper'
import UnderNavBlock from 'shared-components/layout/UnderNavBlock'
import styleUtils from 'shared-components/styles/util.module.css'
import Logo from 'shared-svg/AuthorityMedia.svg'
import { DSGetDealBar } from 'types.sg.ds'
import styles from './Layout.module.css'

const AdminPanel = dynamic(() => import('shared-components/layout/AdminPanel'), { ssr: false })
const NotificationsLayer = dynamic(() => import('shared-components/layout/NotificationsLayer'))

const firstLoad = { current: true }
const Layout: React.FC = ({ children }) => {
  const { isPreview, asPath } = useRouter()
  const { queue } = NotificationsContext.useContainer()
  const { ready, theme } = ThemeContext.useContainer()
  const { showAdminPanel } = CommonDataContext.useContainer()

  useEffect(() => {
    if (!ready) {
      return
    }

    document.body.classList[theme === 'dark' ? 'add' : 'remove'](styleUtils.darkTheme)
  }, [ready, theme])

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false
      return
    }
    window.freestar?.queue.push(() => {
      window.freestar?.trackPageview()
      window.freestar?.deleteStickyFooter()
      window.freestar?.newStickyFooter()
    })
  }, [asPath])
  const getDealbar = useCallback(async (...args: Parameters<DSGetDealBar>) => {
    const dataSource = await getds()
    return dataSource.getDealBar(...args)
  }, [])
  return (
    <PlaywireContext.Provider
      initialState={{
        inst: null,
        enabled: !publicConfig('adsDisabled'),
        adClassList: publicConfig('adClassList'),
      }}
    >
      <FreestarContext.Provider
        initialState={{
          enabled: !publicConfig('adsDisabled'),
          adClassList: publicConfig('adClassList'),
          publisher: publicConfig('freestarPublisher'),
        }}
      >
        <UIVariantContext.Provider
          initialState={{ postPreviewScore: 'ring', buttonStyle: 'outline' }}
        >
          <DealBarContext.Provider initialState={{ getDealbar, dealbar: null }}>
            {showAdminPanel ? <AdminPanel editPostTpl={publicConfig('postEditLink')} /> : null}
            <Header />
            <UnderNavBlock />
            <main className={clsx(styles.main, styleUtils.main)} ref={useOutboundLinkListener()}>
              {children}
            </main>
            <Footer logo={Logo} logoLink={{ href: publicConfig('authorityMediaLink') }}>
              <div className={styles.gdrInfo}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions*/}
                <a
                  className={clsx('change-consent', styles.gdrLink)}
                  onClick={() => window.__uspapi?.('displayUspUi')}
                >
                  Do Not Sell My Personal Information
                </a>

                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions*/}
                <a
                  className={clsx('change-consent', styles.gdrLink)}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onClick={() => window.__tcfapi?.('displayConsentUi', 2, () => {})}
                >
                  Change Consent
                </a>
              </div>
            </Footer>
            <PopupWrapper />
            <PageTransition />
            {queue.length ? <NotificationsLayer /> : null}
            {!isPreview ? <Thirdparty /> : null}
          </DealBarContext.Provider>
        </UIVariantContext.Provider>
      </FreestarContext.Provider>
    </PlaywireContext.Provider>
  )
}

export default Layout
