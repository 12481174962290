import Head from 'next/head'

interface FreestarScriptProps {
  publisher: string
}

const FreestarScript: React.VFC<FreestarScriptProps> = ({ publisher }) => (
  <Head>
    <link rel="stylesheet" href={`https://a.pub.network/${publisher}/cls.css`} />
    <link rel="preconnect" href="https://a.pub.network/" crossOrigin={'anonymous'} />
    <link rel="preconnect" href="https://b.pub.network/" crossOrigin={'anonymous'} />
    <link rel="preconnect" href="https://c.pub.network/" crossOrigin={'anonymous'} />
    <link rel="preconnect" href="https://d.pub.network/" crossOrigin={'anonymous'} />
    <link rel="preconnect" href="https://secure.quantserve.com/" crossOrigin={'anonymous'} />
    <link rel="preconnect" href="https://rules.quantcount.com/" crossOrigin={'anonymous'} />
    <link rel="preconnect" href="https://pixel.quantserve.com/" crossOrigin={'anonymous'} />
    <link rel="preconnect" href="https://cmp.quantcast.com/" crossOrigin={'anonymous'} />
    <link rel="preconnect" href="https://btloader.com/" crossOrigin={'anonymous'} />
    <link rel="preconnect" href="https://api.btloader.com/" crossOrigin={'anonymous'} />
    <link
      rel="preconnect"
      href="https://confiant-integrations.global.ssl.fastly.net"
      crossOrigin={'anonymous'}
    />

    <script
      data-cfasync="false"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
           window.freestar = window.freestar || {};
            window.freestar.hitTime = Date.now();
            window.freestar.queue = window.freestar.queue || [];
            window.freestar.config = window.freestar.config || {};
            window.freestar.debug = window.location.search.indexOf('fsdebug') === -1 ? false : true;
            window.freestar.config.enabled_slots = [];
            window.freestar.initCallback = function () {
              window.freestar.newAdSlots(window.freestar.config.enabled_slots);
            };
          `,
      }}
    />
  </Head>
)

export default FreestarScript
