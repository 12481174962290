import { DSPostContext } from 'shared-definitions/types'
import { appEventListeners } from './app-events-listeners'

interface PollVotedEvent {
  event: 'poll-voted'
  postMeta: DSPostContext
}

interface OutboundLinkEvent {
  event: 'outbound-link'
  desc: string
  postMeta: DSPostContext
}

interface PageScrolledEvent {
  event: 'page-scrolled'
  progress: number
  postMeta: DSPostContext
}

interface SearchResultClickedEvent {
  event: 'search-result-clicked'
  desc: string
  queryId: string
  objectId: string
  position: number
  postMeta: DSPostContext
}

export type AppEvent =
  | PollVotedEvent
  | OutboundLinkEvent
  | PageScrolledEvent
  | SearchResultClickedEvent

export function appEvent(e: AppEvent): void {
  appEventListeners[e.event].forEach(h => h(e as never)) // TODO: fix "never" hack
}
