import clsx from 'clsx'
import stylesCommon from 'shared-components/layout/TopSearchControl.TopSearchPlaceholder.module.css'
import { getds } from 'ds'
import dynamic from 'next/dynamic'
import React, { useCallback, useState } from 'react'
import styles from 'shared-components/layout/TopSearchControl.module.css'
import { BasicStyledComponent, DynamicLoadingComponent } from 'shared-definitions/types'
import Magnifier from 'shared-svg/Magnifier.svg'

interface TopSearchLoadingProps extends DynamicLoadingComponent {
  onMouseOver?: () => void
}
const TopSearchLoading: React.VFC<TopSearchLoadingProps> = ({ onMouseOver }) => (
  <div className={styles.container}>
    <button
      type="button"
      className={clsx(stylesCommon.button, styles.buttonSearch)}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
      aria-label="Search"
    >
      <Magnifier className={stylesCommon.icon} />
    </button>
  </div>
)

const TopSearch = dynamic(() => import('shared-components/layout/TopSearch'), {
  loading: TopSearchLoading,
  ssr: false,
})
const TopSearchControl = dynamic(() => import('shared-components/layout/TopSearchControl'), {
  loading: TopSearchLoading,
  ssr: false,
})

type TopSearchPlaceholderProps = BasicStyledComponent

const TopSearchPlaceholder: React.VFC<TopSearchPlaceholderProps> = () => {
  const [activated, setActivated] = useState(false)
  const mouseOverCb = useCallback(() => setActivated(true), [])
  const searchHandler = useCallback(async (query: string, perPage: number) => {
    const dataSource = await getds()
    const { data } = await dataSource.getSearchPage(query, 1, perPage)
    return data.posts
  }, [])

  if (!activated) {
    return <TopSearchLoading onMouseOver={mouseOverCb} />
  }

  return <TopSearch searchHandler={searchHandler} control={TopSearchControl} />
}

export default TopSearchPlaceholder
