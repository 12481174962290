import { createContainer } from 'shared-components/unstated'
// import { publicConfig } from 'config'

export interface FreestarValueProps {
  adClassList: readonly string[]
  enabled: boolean
  publisher: string | undefined
}

export type FreestarContextProps = FreestarValueProps

export const FreestarContext = createContainer<FreestarContextProps, FreestarValueProps>(
  initial => {
    if (!initial) {
      throw new Error(`Undefined freestar initial props ${JSON.stringify(initial)}`)
    }

    return {
      adClassList: initial.adClassList,
      enabled: initial.enabled,
      publisher: initial.publisher,
    }
  }
)
